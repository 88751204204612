import React from 'react';
import '../styles/Friends.css';
import pcoinIcon from '../assets/icons/pcoin.svg'; // иконка для pcoin
import spinIcon from '../assets/icons/spin.svg'; // иконка для огня
import copyIcon from '../assets/icons/copyIcon.svg';
import shareIcon from '../assets/icons/shareIcon.svg';

const Friends = () => {
  return (
    <div className="friends">
      <h2>Friends</h2>
      <p>Invite your friends and get additional bonuses</p>
      
      <div className="bonus-cards">
        <div className="card friends-card">
          <p>Friends</p>
          <div className="bonus-icons">
            <img src={spinIcon} alt="spinIcon" className="bonus-icon spin-icon" />
            <span>+1</span>
          </div>
        </div>
        <div className="card premium-friends-card">
          <p>Premium friends</p>
          <div className="bonus-icons">
            <img src={spinIcon} alt="spinIcon" className="bonus-icon spin-icon" />
            <span>+1 </span>
            <img src={pcoinIcon} alt="P Coin" className="bonus-icon" />
            <span>+25</span>
          </div>
        </div>
      </div>
      
      <div className="invite-section">
        <div className="invite-link">
          <input type="text" readOnly value="t.me/ref=1257236" />
          <button className="share-btn">
            <img src={shareIcon} alt="Share" />
          </button>
        </div>
        <button className="copy-btn" onClick={() => navigator.clipboard.writeText("t.me/ref=1257236")}>
          <img src={copyIcon} alt="Copy" />
        </button>
      </div>

      <div className="friends-stats-combined">
        <div className="stats-item">
          <p>Invited friends</p>
          <span>12</span>
        </div>
        <div className="stats-item">
          <p>Premium friends</p>
          <span>4</span>
        </div>
      </div>
    </div>
  );
};

export default Friends;
