import React from 'react';
import Quests from '../components/Quests';
import '../styles/TaskPage.css';

const TaskPage = () => {
  return (
    <div className="task-page">
      <Quests />
    </div>
  );
};

export default TaskPage;
