import React from 'react';
import '../styles/InviteButton.css';
import arrowIcon from '../assets/icons/arrow.svg';

const InviteButton = ({ text, onClick }) => {
  return (
    <button className="invite-button" onClick={onClick}>
      <span className="invite-text">{text}</span>
      <img src={arrowIcon} alt="Arrow" className="invite-arrow" />
    </button>
  );
};

export default InviteButton;
