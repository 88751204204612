import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import homeIcon from '../assets/icons/home.svg';
import homeActiveIcon from '../assets/icons/homeActive.svg';
import socialIcon from '../assets/icons/social.svg';
import socialActiveIcon from '../assets/icons/socialActive.svg';
import groupIcon from '../assets/icons/group.svg';
import groupActiveIcon from '../assets/icons/taskActive.svg';
import backIcon from '../assets/icons/back.svg';
import spinIcon from '../assets/icons/spin.svg';
import '../styles/Footer.css';

const Footer = () => {
  const [activeTab, setActiveTab] = useState('home');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Сбрасываем активный таб при изменении пути
    const currentPath = location.pathname;
    if (currentPath === '/') {
      setActiveTab('home');
    } else if (currentPath === '/tasks') {
      setActiveTab('tasks');
    } else if (currentPath === '/Friends') {
      setActiveTab('Friends');
    }
  }, [location.pathname]); // следим за изменением пути

  const handleNavigation = (tab, path) => {
    setActiveTab(tab);
    navigate(path);
  };

  const renderIcon = (tab) => {
    switch (tab) {
      case 'home':
        return activeTab === 'home' ? homeActiveIcon : homeIcon;
      case 'tasks':
        return activeTab === 'tasks' ? groupActiveIcon : groupIcon;
      case 'Friends':
        return activeTab === 'Friends' ? socialActiveIcon : socialIcon;
      default:
        return homeIcon;
    }
  };

  return (
    <div className="footer">
      <div className="footer-container">
        {/* Кнопка назад, если не на главной странице */}
        {location.pathname !== '/' && (
          <button 
            className="back-btn" 
            onClick={() => navigate(-1)}
          >
            <img src={backIcon} alt="Back" />
          </button>
        )}
        <button 
          className={`footer-btn ${activeTab === 'home' ? 'active' : ''}`} 
          onClick={() => handleNavigation('home', '/')}
        >
          <img src={renderIcon('home')} alt="Home"/>
        </button>
        <button 
          className={`footer-btn ${activeTab === 'tasks' ? 'active' : ''}`} 
          onClick={() => handleNavigation('tasks', '/tasks')}
        >
          <img src={renderIcon('tasks')} alt="Tasks" />
        </button>
        <button 
          className={`footer-btn ${activeTab === 'Friends' ? 'active' : ''}`} 
          onClick={() => handleNavigation('Friends', '/Friends')}
        >
          <img src={renderIcon('Friends')} alt="Friends" />
        </button>
      </div>

      {location.pathname === '/' && (
        <button className="spin-btn">
          <span className="spin-text">FREE SPIN</span>
          <img src={spinIcon} alt="Spin" />
          <span className="spin-count">5</span>
        </button>
      )}
    </div>
  );
};

export default Footer;
