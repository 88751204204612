import React from 'react';
import '../styles/PopUp.css';

const PopUp = ({ icon, points, onClose }) => {
  return (
    <div className="popup-overlay" onClick={onClose}>
      <div className="popup" onClick={(e) => e.stopPropagation()}>
        <img src={icon} alt="Prize Icon" className="popup-icon" />
        <h2>Your winnings</h2>
        <p className="popup-points">+{points} Points</p>
        <button className="popup-button" onClick={onClose}>
          Great!
        </button>
      </div>
    </div>
  );
};

export default PopUp;
