import React, { useRef, useEffect, useState } from 'react';
import { Wheel } from 'spin-wheel';
import CoinDisplay from './CoinDisplay';
import PopUp from './PopUp'; // Импортируем PopUp
import pcoin from '../assets/icons/pcoin.svg';
import tcoin from '../assets/icons/tcoin.svg';
import scoin from '../assets/icons/scoin.svg';
import centerCircle from '../assets/icons/spin.svg';
import '../styles/Wheel.css';
import InviteButton from './InviteButton';

const WheelComponent = () => {
  const wheelContainer = useRef(null);
  const [wheel, setWheel] = useState(null);
  const [result, setResult] = useState(null);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false); // Состояние для отображения PopUp

  const items = [
    { label: '5', image: pcoin },
    { label: '10', image: tcoin },
    { label: '5', image: scoin },
    { label: '50', image: tcoin },
    { label: '10', image: pcoin },
    { label: '10', image: pcoin },
    { label: '3', image: scoin },
    { label: '0.5', image: scoin },
    { label: '25', image: pcoin },
    { label: '25', image: pcoin },
    { label: '100', image: pcoin },
    { label: '5', image: tcoin },
  ];

  const [coins, setCoins] = useState([
    { name: 'pcoin', amount: 0, icon: pcoin },
    { name: 'tcoin', amount: 0, icon: tcoin },
    { name: 'scoin', amount: 0, icon: scoin },
  ]);

  useEffect(() => {
    const preloadImages = async () => {
      const imagePromises = items.map((item) => {
        return new Promise((resolve) => {
          if (item.image) {
            const img = new Image();
            img.src = item.image;
            img.onload = resolve;
          } else {
            resolve();
          }
        });
      });

      await Promise.all(imagePromises);
      setImagesLoaded(true);
    };

    preloadImages();
  }, []);

  useEffect(() => {
    if (wheelContainer.current && !wheel && imagesLoaded) {
      const props = {
        items: items.map((item) => ({
          label: item.label,
          image: item.image ? createImageElement(item.image) : null,
          imageScale: 1.5,
          imageRadius: 0.8,
        })),
        itemBackgroundColors: ['#FF7F50', '#8A2BE2'],
        rotationResistance: -35,
        itemLabelFont: 'sans-serif',
        itemLabelColors: ['#FFF'],
        itemLabelFontSizeMax: 30,
        itemLabelAlign: 'center',
        itemLabelRadius: 0.5,
        itemLabelRotation: 90,
        itemLabelStrokeWidth: 1,
        lineColor: 'transparent',
        isInteractive: false,
        borderWidth: 0,
        radius: 1,

        onRest: (event) => {
          const currentIndex = event.currentIndex;
          const selectedItem = items[currentIndex];
          setResult(selectedItem);
          setIsSpinning(false);

          // Обновляем количество коинов и показываем PopUp
          updateCoinAmount(selectedItem);
          setShowPopUp(true);
        },
      };

      const newWheel = new Wheel(wheelContainer.current, props);
      setWheel(newWheel);
    }
  }, [wheel, imagesLoaded]);

  const createImageElement = (src) => {
    const img = new Image();
    img.src = src;
    return img;
  };

  const spinWheel = () => {
    if (wheel && !isSpinning) {
      setIsSpinning(true);
  
      const weightedIndices = [];
  
      items.forEach((item, index) => {
        const label = item.image;
        if (label === pcoin) {
          weightedIndices.push(...Array(70).fill(index));
        } else if (label === tcoin) {
          weightedIndices.push(...Array(20).fill(index));
        } else if (label === scoin) {
          weightedIndices.push(...Array(10).fill(index));
        }
      });
  
      const randomIndex = weightedIndices[Math.floor(Math.random() * weightedIndices.length)];
      wheel.spinToItem(randomIndex, 3000, true, 3, 1);
    }
  };

  const handleWheelClick = () => {
    spinWheel();
  };

  const updateCoinAmount = (selectedItem) => {
    const coinValue = parseFloat(selectedItem.label);
    const coinType = selectedItem.image === pcoin ? 'pcoin' : selectedItem.image === tcoin ? 'tcoin' : 'scoin';

    setCoins((prevCoins) =>
      prevCoins.map((coin) =>
        coin.name === coinType ? { ...coin, amount: coin.amount + coinValue } : coin
      )
    );
  };

  return (
    <div className="wheel-container">
      <CoinDisplay coins={coins} />

      <div ref={wheelContainer} className="wheel" onClick={handleWheelClick}>
        <img src={centerCircle} alt="Center Circle" className="center-circle" />
      </div>

      <InviteButton text="Invite your friends for free spins" onClick={() => alert('Button clicked!')} />

      {showPopUp && result && (
        <PopUp
          icon={result.image}
          points={result.label}
          onClose={() => setShowPopUp(false)}
        />
      )}
    </div>
  );
};

export default WheelComponent;
