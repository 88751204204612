import React from 'react';
import QuestItem from './QuestItem';
import telegramIcon from '../assets/icons/telegram.svg';
import discordIcon from '../assets/icons/discord.svg';
import xIcon from '../assets/icons/x.svg';
import mediumIcon from '../assets/icons/medium.svg';
import youtubeIcon from '../assets/icons/youtube.svg';
import '../styles/Quests.css';

const Quests = () => {
  const quests = [
    { icon: telegramIcon, title: 'Subscribe Telegram channel', points: 25, buttonText: 'MAKE', gradientClass: 'telegram-gradient' },
    { icon: discordIcon, title: 'Subscribe Discord', points: 25, buttonText: 'CLAIM', gradientClass: 'discord-gradient' },
    { icon: xIcon, title: 'Subscribe X', points: 25, buttonText: 'CLAIM', gradientClass: 'x-gradient' },
    { icon: mediumIcon, title: 'Subscribe Medium', points: 25, buttonText: 'CLAIM', gradientClass: 'medium-gradient' },
    { icon: youtubeIcon, title: 'Subscribe YouTube', points: 25, buttonText: 'MAKE', gradientClass: 'youtube-gradient' },
  ];

  return (
    <div className="quests">
      <h2>Quests</h2>
      <div className="quest-list">
        {quests.map((quest, index) => (
          <QuestItem
            key={index}
            icon={quest.icon}
            title={quest.title}
            points={quest.points}
            buttonText={quest.buttonText}
            gradientClass={quest.gradientClass} // передаем класс градиента
          />
        ))}
      </div>
    </div>
  );
};

export default Quests;
