import React from 'react';
import '../styles/QuestItem.css';
import coinIcon from '../assets/icons/pcoin.svg';

const QuestItem = ({ title, icon, points, tickets, buttonText, gradientClass }) => {
  return (
    <div className="quest-item">
      <div className="quest-top">
        <div className={`quest-icon-gradient ${gradientClass}`}>
          <img src={icon} alt="Icon" className="icon-image" />
        </div>
        <div className="quest-title">{title}</div>
        <div className="quest-status">{tickets ? `${tickets} tickets` : ''}</div>
      </div>
      <div className="quest-bottom">
        <div className="quest-points">
          <img src={coinIcon} alt="Coin Icon" />
          <span>+{points} points</span>
        </div>
        <button className="quest-action">{buttonText}</button>
      </div>
    </div>
  );
};

export default QuestItem;
