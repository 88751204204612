import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Wheel from './components/Wheel';
import TaskPage from './pages/TaskPage';
import Friends from './components/Friends';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Wheel />} />
          <Route path="/tasks" element={<TaskPage />} />
          <Route path="/friends" element={<Friends />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
