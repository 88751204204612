import React from 'react';
import pcoin from '../assets/icons/pcoin.svg';
import tcoin from '../assets/icons/tcoin.svg';
import scoin from '../assets/icons/scoin.svg';
import '../styles/CoinDisplay.css'; // Импорт стилей

const CoinDisplay = ({ coins }) => {
  return (
    <div className="coin-display">
      {coins.map((coin, index) => (
        <div key={index} className="coin-wrapper">
          <img src={coin.icon} alt="coin" className="coin-icon" />
          <span className="coin-value">{coin.amount}</span>
        </div>
      ))}
    </div>
  );
};

export default CoinDisplay;
