import React from 'react';
import profileIcon from '../assets/icons/User.png';
import trophyIcon from '../assets/icons/trophy.svg';
import bagIcon from '../assets/icons/bag.svg';
import bellIcon from '../assets/icons/bell.svg';
import '../styles/Header.css';

const Header = () => {
  return (
    <div className="header-container">
      <div className="profile-section">
        <img src={profileIcon} alt="Profile" className="profile-icon" />
        <span className="profile-name">Username</span>
      </div>
      <div className="buttons-section">
        <button className="header-button">
          <img src={trophyIcon} alt="Trophy" className="header-icon" />
        </button>
        <button className="header-button">
          <img src={bagIcon} alt="Bag" className="header-icon" />
        </button>
        <button className="header-button">
          <img src={bellIcon} alt="Bell" className="header-icon" />
        </button>
      </div>
    </div>
  );
};

export default Header;
